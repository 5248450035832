<template>
    <footer class="conteiner bg-primary d-flex flex-column justify-content-center align-items-around ">
        <div class="row d-flex align-items-around my-md-3 my-2 m-0">
            <div class="col-lg-4 col-sm-6 col-12">
                <img class="zoom-hover" @click.stop="router.push({ path: '/'})" :src="wiks_logo" alt="logo wiks" width="261" height="199" loading="lazy"
                    style="width: 180px; height: 150px; object-fit: scaledown; cursor: pointer;">
            </div>
            <div class="col-lg-2 col-sm-6 col-12">
                <ul class="list-unstyled text-sm-start text-center mx-lg-3 mx-5" v-if="has_session">
                    <li v-for="(option, index ) in options" :key="`option-f-${index}`"  class="zoom-hover"> 
                        <a class="text-decoration-none text-white text-uppercase dmsans-bold" @click.stop="router.push({ name: option.route})" style="cursor: pointer;">{{ option.label }}</a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 col-sm-6 col-12">
                <ul class="list-unstyled text-sm-start text-center mx-lg-3 mx-5">
                    <li v-for="(option, index ) in options2" :key="`option-f-${index}`" class="zoom-hover" > 
                        <a class="text-decoration-none text-white text-uppercase dmsans-bold" @click.stop="router.push({ name: option.route})" style="cursor: pointer;">{{ option.label }}</a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-4 col-sm-6 col-12">
                <div class="d-flex flex-row justify-content-center">
                    <a href="https://play.google.com/store/apps/details?id=com.wiks.mx" target="_blank" rel="external">
                        <img :src="google_play" class="zoom-hover me-1 mb-3" alt="logo Playstore" width="120" height="40" loading="lazy">
                    </a>
                    <a href="https://apps.apple.com/gb/app/wiks/id1662030786" target="_blank">
                        <img :src="appstore" class="zoom-hover ms-1 mb-3" alt="logo AppStore" width="120" height="40" loading="lazy">
                    </a>
                </div>
                <div class="d-flex flex-row justify-content-center ">
                    <a href="#"
                        style="opacity: 1;">
                        <img :src="fb_logo" class="zoom-hover" alt="logo facebook" width="50" height="50" loading="lazy">
                    </a>
                    <a class="mx-2"
                        href="#" style="opacity: 1;">
                        <img :src="ins_logo" class="zoom-hover" alt="logo instagram" width="50" height="50" loading="lazy">
                    </a>
                    <a class="me-2" href="#" style="opacity: 1;">
                        <img :src="tk_logo" class="zoom-hover" alt="logo twitter" width="50" height="50" loading="lazy">
                    </a>
                    <a href="#" style="opacity: 1;">
                        <img :src="whats_logo" class="zoom-hover" alt="logo whatsapp" width="50" height="50" loading="lazy">
                    </a>
                </div>
            </div>
        </div>
        <div class="col-12 d-flex  flex-wrap justify-content-around align-items-center mb-5 bg-white p-0">
            <img  v-for="(image, index) in images" :key="`bank_${index}`"
                class="img_hover"
                style="object-fit: scale-down;"
                :height="image.size"
                :src="image.image" 
                loading="lazy"
                :alt="image.alt">
        </div>

        <div class="col-12 mb-2">
            <a href="https://monocilindrero.com/" target="_blank"
                class="text-decoration-none text-white description-xs mb-1"
                style="letter-spacing: 1px; font-size: 15px;">

                Desarrollado por                                
                <b class="dmsans-semibold" style="color: #FF0000">monocilindrero.com </b>
                <img :src="mono" style="max-height: 25px;" class="m-1">
            </a>
        </div>
    </footer>
</template>

<script setup>
    const wiks_logo = require('ASSETS/wiks_logo')
    const google_play = require('ASSETS/icons/googleplay')
    const appstore = require('ASSETS/icons/appstore')
    const fb_logo = require('ASSETS/icons/facebook-white')
    const ins_logo = require('ASSETS/icons/instagram-white')
    const tk_logo = require('ASSETS/icons/tiktok-white')
    const whats_logo = require('ASSETS/icons/wa-white')

    const openpay = require('ASSETS/images/openpay')
    const visa = require('ASSETS/images/visa')
    const mastercard = require('ASSETS/images/mastercard')
    const american = require('ASSETS/images/americanexpress')
    const hsbc = require('ASSETS/images/hsbc')
    const bbva = require('ASSETS/images/bbva')
    const banamex = require('ASSETS/images/banamex')
    const azteca = require('ASSETS/images/azteca')
    const santander = require('ASSETS/images/santander')
    const scotibank = require('ASSETS/images/scotiabank')

    const mono = require('ASSETS/images/monologo')





    import { ref, computed, reactive, resolveComponent } from 'vue';
    import { useRouter } from 'vue-router'
    import { useStore } from 'vuex';
    

    //hooks
    const store = useStore();
    const router = useRouter();

    //variables
    const options = [
        {
            label: 'INICIO',
            route: 'post-home'
        },
        // {
        //     label: 'PROMOCIONES'
        // },
        {
            label: 'PRODUCTOS',
            route: 'brands'
        },
        {
            label: 'CARRITO',
            route: 'shopping-cart'
        },
        {
            label: 'WIKS PUNTOS',
            route: 'post-home'
        }
    ]
    const options2 = [
        {
            label: 'Politicas de privacidad',
            route: ''
        },
        {
            label: 'Terminos y condiciones',
            route: ''
        },
        {
            label: 'Contacto',
            route: ''
        },

    ]


    //footer images

    const images = [
        {
            image: openpay,
            alt: 'openpay',
            size: 75
        },
        {
            image: visa,
            alt: 'visa',
            size: 75
        },
        {
            image: mastercard,
            alt: 'mastercard',
            size: 75
        },
        {
            image: american,
            alt: 'american express',
            size: 75
        },
        {
            image: hsbc,
            alt: 'hsbc',
            size: 100
        },
        {
            image: bbva,
            alt: 'bbva',
            size: 75
        },
        {
            image: banamex,
            alt: 'banamex',
            size: 100
        },
        {
            image: azteca,
            alt: 'azteca',
            size: 100
        },
        {
            image: santander,
            alt: 'santander',
            size: 100
        },
        {
            image: scotibank,
            alt: 'scotiabank',
            size: 90
        }
    ]
    //computed
    const has_session  = computed(() => (store.getters['getLogin']));
</script>
<style scoped>
.zoom-hover:hover{
        transform: scale(1.12);
    } 
</style>
