<template>
    <section class="offer_card d-flex justify-content-between align-items-center px-2 m-0 py-4 border border-1 cart_hover bg-white"
        :style="(favorite) ? 'height: 300px !important' : ''">

        <section>
            <!-- Favorite -->
            <section>
                <img @click.stop="markFavorite"
                    class="icon icon_hover" 
                    :src="heart_fill"  
                    alt="" 
                    v-if="data.bookmark">

                <img @click.stop="markFavorite"
                    class="icon icon_hover" 
                    :src="heart" 
                    alt="" 
                    v-else>
            </section>

            <!-- Product image -->
            <section class="">
                <img :src="local_image || pl_img" 
                    class="offer_image" 
                    alt="product image">
            </section>
            
            <section>
                <p class="d-block dmsans-black text_shadow_xtz my-3">
                    {{ data.product_name }}
                </p>
            </section>
        </section>


        <!-- Add products to market -->
        <section class="flex-center my-2 px-3 pe-2 w-100" v-if="!favorite">

            <div class="w-50 flex-start-center">
                <p class="d-block dmsans-black text-primary m-0" style="font-size: 17px">
                    $ {{ formatAmount(data.price,2) }}
                </p>
            </div>

            <div class="w-50">

                <div class="btn-group btn-group-sm" role="group" 
                    aria-label="button for stock"
                    v-show="qty > 0">

                    <button @click.stop="sendToCart('min')"
                        :disabled="qty === 0 || updating_cart"
                        type="button" 
                        class="btn btn-primary"
                        style="border-bottom-left-radius: 25px; border-top-left-radius: 25px;">
                            <img :src="less" alt="" v-if="!updating_cart">

                            <div class="spinner-border spinner-border-sm text-white" 
                                style="margin: 0px 1px" 
                                role="status" 
                                v-else>
                                    <span class="visually-hidden">Loading...</span>
                            </div>
                    </button>
                    <button type="button" class="btn btn-primary">
                        <b class="m-0">{{ qty }}</b>
                    </button>   
                    <button @click.stop="sendToCart('add')"
                        type="button" 
                        :disabled="updating_cart"
                        class="btn btn-primary"
                        style="border-bottom-right-radius: 25px; border-top-right-radius: 25px;">
                            <img :src="more" alt="" v-if="!updating_cart">

                            <div class="spinner-border spinner-border-sm text-white" 
                                style="margin: 0px 1px" 
                                role="status" 
                                v-else>
                                    <span class="visually-hidden">Loading...</span>
                            </div>
                    </button>
                </div>

                <button @click.stop="sendToCart('add')"
                    type="button" 
                    :disabled="updating_cart"
                    class="btn btn-light flex-center btn-sm btn-pill shadow_xtz"
                    style="width: 115.8px; height: 38px;"
                    v-show="qty == 0">
                        <img :src="more_blue" alt="" v-if="!updating_cart">
                        <div class="m-0 my-2" v-if="!updating_cart">
                            <b>Carrito</b>    
                        </div>

                        <div class="spinner-border spinner-border-sm text-dark" 
                            style="margin: 0px 1px" 
                            role="status" 
                            v-else>
                                <span class="visually-hidden">Loading...</span>
                        </div>
                </button>
                <!-- @click.stop="sendToCart" -->

            </div>
            
        </section>

    </section>
</template>

<script setup>
    // Import
    import { useStore } from 'vuex';
    import { formatAmount } from 'COMPOSABLES/formatAmount'
    import { ref, computed } from 'vue';
    // Images
    const pl_img = require('ASSETS/placeholder')
    const heart = require('ASSETS/icons/heart')
    const heart_fill = require('ASSETS/icons/heart_fill')
    const less = require('ASSETS/icons/minus_white')
    const more = require('ASSETS/icons/plus_white')
    const more_blue = require('ASSETS/icons/add')

    // Hooks
    const store = useStore()

    // Emits
    const emits = defineEmits(['mark:favorite', 'mark:removeFavorite', 'change:updatingCart'])

    // Props
    const props = defineProps({
        data: {
            type: Object,
            required: false,
            default: () => ({ title: 'default_title', price: 'default_price', img: require('ASSETS/placeholder')})
        },
        favorite: {
            type: Boolean,
            required: false,
            default: false
        },
        updating_cart: {
            type: Boolean,
            required: false,
            default: false
        }
    })

    // Computeds
    const local_image = computed(() => {
        if(props.data.picture !== undefined && props.data.picture !== null) {
            return props.data.picture.replace("undefined", props.data.product_code)
        }
        return null
    })

    // Variables
    const qty = ref(0)

    // Methods
    const sendToCart = async(mov = null) => {
        emits('change:updatingCart', true)

        if(mov === 'add') {
            qty.value = qty.value + 1
        }
        else if(mov === 'min') {
            qty.value = qty.value - 1
        }

        await store.dispatch('sales/addToShoppingCart', {
            code: props.data.product_code ?? props.data.code, 
            qty: qty.value, 
            total: formatAmount(props.data.price, 2), 
            promo: false, 
            name: props.data.product_name
        })

        emits('change:updatingCart', false)
    }

    const markFavorite = () => {
        emits('mark:favorite', props.data.product_code ?? props.data.code)
    }
    const removeFavorite = () => {
        emits('mark:removeFavorite', props.data.product_code ?? props.data.code)
    }

</script>







